import React from 'react';
import { format } from 'date-fns'
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

// Components
import Delete from './Delete';
import Source from './Source';
import ScrapeForDeals from './ScrapeForDeals';
import Published from './Published';
import ToggleCollection from './ToggleCollection';

// UI
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddLinkIcon from '@mui/icons-material/AddLink';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import EditIcon from '@mui/icons-material/Edit';
import { grey } from '@mui/material/colors';

const Card = ({ data, edit, addTo, todo, disabled }) => {
  const handleEdit = () => edit(data);
  const handleAddTo = () => addTo(data);
  const handleToDo = () => todo(data);

	const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useDraggable({
    id: data.id,
    disabled,
    data: {
      publishedOn: data.data().publishedOn,
      title: data.data().title,
      link: data.data().link,
      source: data.data().partOf.news.sources[0],
    }
  });

  const article = data.data();

  return (
    <Box
      ref={setNodeRef}
      sx={{
        transform: CSS.Transform.toString(transform),
        border: isDragging ? '2px solid' : null,
        transition,
      }}
      {...attributes}
      {...listeners}
    >
      <Stack
        direction="column"
        sx={{ border: '1px solid #eee' }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="caption" sx={{ paddingLeft: (theme) => theme.spacing(1) }}>
            {format(article.publishedOn.toDate(), 'dd MMM, HH:mm')}
          </Typography>

          <Stack direction="row" spacing={0}>
            <Published data={data} />

            <ToggleCollection name="top" data={data} />

            <ToggleCollection name="featured" data={data} />

            <IconButton onClick={handleToDo}>
              <PlaylistAddIcon fontSize="small" />
            </IconButton>

            <ScrapeForDeals data={data} />

            <IconButton size="small" onClick={handleAddTo} disabled={article.published}>
              <AddLinkIcon fontSize="small" />
            </IconButton>

            <IconButton size="small" onClick={handleEdit}>
              <EditIcon fontSize="small" />
            </IconButton>
            <Delete id={data.id} />
          </Stack>
        </Stack>

        <Divider />

        <Box sx={{ padding: 1 }}>
          <Stack direction="row" spacing={1}>
            {article.image && article.image.static &&
              <Avatar
                src={article.image.static.url}
                variant="square"
                sx={{
                  width: 82,
                  height: 82,
                }}
              /> 
            }

            <Stack spacing={0}>
              <Source id={article.partOf.news.sources[0]} />

              <Typography
                variant="body2"
                sx={{ fontWeight: 'bold', color: grey[800] }}
                component={Link}
                href={article.link}
                target="_blank"
                rel="noopener"
                underline="none"
              >
                {article.title}
              </Typography>
            </Stack>
          </Stack>
        </Box>

        {article.links && article.links.length
          ? <Box>
              <Divider textAlign="left" sx={{ fontSize: 12 }}>
                Alternative sources:
              </Divider>

              <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
                {article.links.map((altSource) =>
                  <Chip
                    key={altSource.url}
                    component={Link}
                    href={altSource.url}
                    target="_blank"
                    label={altSource.source}
                    size="small"
                    sx={{ margin: (theme) => theme.spacing(0.5) }}
                  />   
                )}
              </Stack>
            </Box>
          : null
        }
      </Stack> 
    </Box>
  );
};

export default Card;
